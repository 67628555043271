import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import XHR from "i18next-xhr-backend"
import React from "react"
import { data } from "./mock.js"
import api from "../api"

function simpleData() {
  const results = data.feed.entry
  let en = { translations: {} }
  let cn = { translations: {} }
  if (results && results.length > 0) {
    for (let n in results) {
      let item = results[n]
      let eng = item.gsx$english.$t
      let id = item.gsx$id.$t
      if (id !== "") {
        en.translations[id] = eng
        cn.translations[id] = item.gsx$simplifiedchinese.$t
      } else {
        en.translations[eng] = eng
        cn.translations[eng] = item.gsx$simplifiedchinese.$t
      }
    }
  }
  return { en, cn }
}

function loadCurrent(url, options, callback, data) {
  api
    .get(
      "https://spreadsheets.google.com/feeds/list/1SgRQnq4D5a9dSDUW4wc1aak9Ew5xKOI86F8MuPJMjUc/od6/public/values?alt=json"
    )
    .then((res) => {
      console.log(url)
      callback([res.data.feed.entry, url], { status: res.status })
    })
}

function parseData(array) {
  const results = array[0]
  const lng = array[1]
  let en = {}
  let cn = {}
  if (results && results.length > 0) {
    for (let n in results) {
      let item = results[n]
      let eng = item.gsx$english.$t
      let id = item.gsx$id.$t
      if (id !== "") {
        en[id] = eng
        cn[id] = item.gsx$simplifiedchinese.$t
      } else {
        en[eng] = eng
        cn[eng] = item.gsx$simplifiedchinese.$t
      }
    }
  }
  return eval(lng)
}

const options = {
  loadPath: "{{lng}}",
  allowMultiLoading: false,
  parse: parseData,
  crossDomain: true,
  withCredentials: false,
  overrideMimeType: false,
  ajax: loadCurrent,
}

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: simpleData(),
    // backend: options,
    ns: ["translations"],
    defaultNS: "translations",
    debug: process.env.NODE_ENV === "development",
    keySeparator: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  })

i18next.languages = ["en", "cn"]

export default i18next
