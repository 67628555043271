import React from "react"
import { ApolloProvider } from "@apollo/client"
import { UserProvider } from "./src/components/userContext"
import { client } from "./src/apollo/client"

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <UserProvider>{element}</UserProvider>
    </ApolloProvider>
  )
}
