import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { FaTwitter, FaInstagram, FaFacebookSquare } from "react-icons/fa"
import theme from "../theme"
import UserContext from "./userContext"
import logo from "../images/logos/color-logo.png"

const Footer = () => {
  const { userData } = useContext(UserContext)
  const { t, i18n } = useTranslation()

  const lang = i18n.language || "en"

  return (
    <StyledFooter>
      <div className="container">
        <div className="lists">
          <div className="list">
            <div className="content">
              <h4>TravelByBit</h4>
              <Link to="/how-to-book">{t("How to book")}</Link>
            </div>
          </div>
          <div className="list">
            <div className="content">
              <h4>{t("Explore")}</h4>

              <Link to="/stats">{t("Stats")}</Link>
            </div>
          </div>
          <div className="list">
            <div className="content">
              <h4>{t("Support")}</h4>
              <Link to="/contact-us">{t("Contact us")}</Link>
            </div>
          </div>
        </div>
        <img src={logo} alt="Travel By Bit" className="logo" />

        <div className="legals">
          <p>Copyright © {new Date().getFullYear()} TravelbyBit</p>
          <p>ABN 88 621 527 357</p>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  width: 100%;
  background: ${theme.greyBack};
  padding: 5em 0 3em 0;

  .container {
    max-width: 1170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .lists {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(234px, 1fr));
    flex-wrap: wrap;

    .list {
      display: flex;
      justify-content: center;

      .content {
        display: flex;
        flex-direction: column;
        width: 12em;
      }

      h4 {
        color: ${theme.body};
        font-size: 18px;
        font-weight: 600;
        font-size: 1.5em;
        margin-bottom: 1rem;
      }

      a {
        text-decoration: none;
        color: ${theme.body};
        font-weight: 200;
        font-size: ${theme.fontSizeSmall};
        margin-bottom: 0.5rem;
      }
    }
  }

  .logo {
    width: 17rem;
    height: auto;
    margin-top: 5em;
    margin-bottom: 2em;
  }

  .socials {
    height: 3em;
    width: 8em;
    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
      background: #fff;
      border-radius: 50px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;

      svg {
        color: ${theme.orange};
        font-size: 18px;
        width: auto;
        margin: auto;
      }

      &:hover {
        background: ${theme.orange};

        svg {
          color: #fff;
        }
      }
    }
  }

  .legals {
    color: #909090;
    text-align: center;
    font-size: 10px;
    font-weight: 200;
    letter-spacing: 0.05rem;
    margin-top: 0.5rem;
  }

  @media only screen and (max-width: 1204px) {
    .container {
      padding: 0 1rem;
    }

    .list {
      margin-bottom: 1.5rem;
    }
  }
`
