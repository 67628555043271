import moment from "moment"
import api from "../api"

function ValidateIPaddress(ipaddress) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true
  }
  console.error(ipaddress)
  return false
}

// Gets object of location data for user
async function getUserLocation() {
  try {
    let res = await fetch("https://jsonip.com", { mode: "cors" }).then((resp) =>
      resp.json()
    )

    let ip
    if (res.ip.includes(",")) {
      ip = res.ip.split(",")[0]
    } else {
      ip = res.ip
    }

    if (!ValidateIPaddress(ip)) return

    let response = await api.post("/account/geolocate", { ip })

    let { city, country, userCurrency, iata } = response.data

    return {
      city,
      country,
      userCurrency,
      iata,
    }
  } catch (e) {
    console.error(e)
    return
  }
}

export const getUserData = async () => {
  let { city, country, userCurrency, iata } = await getUserLocation()

  return {
    userCityCode: iata,
    userCity: city,
    userCountry: country,
    userCurrency,
  }
}

// Used to search through cities from db to find city codes
async function getCityCode(cityName, places = []) {
  let cityCode

  switch (cityName) {
    case "Paris":
      return "PAR"
    case "Berlin":
      return "BER"
    case "Prague":
      return "PRG"
    case "Barcelona":
      return "BCN"
    case "Tokyo":
      return "TYO"
    case "Shanghai":
      return "SHA"
    case "Bali":
      return "DPS"
    case "Phuket":
      return "HKT"
  }

  // Need to improve to fix issues with multiple cities of same name
  places.forEach((place) => {
    Object.values(place).map((el) => {
      if (el && el.toLowerCase() === cityName.toLowerCase()) {
        cityCode = place.iata
      }
    })
  })

  return cityCode
}

// Builds the search params and goes to search
export async function conductSearch(destination, userCityCode, places) {
  let destinationCityCode = await getCityCode(destination, places)

  const originCityCode = userCityCode || "SYD"

  const departOrigin = moment().add(14, "days").format("YYYY-MM-DD")
  const departDestination = moment().add(21, "days").format("YYYY-MM-DD")

  window.open(
    `${process.env.TRAVALA_URL}/flights?from=${originCityCode}&to=${destinationCityCode}&depart=${departOrigin}&return=${departDestination}&kids=0&adults=1&infants=0&class=Economy&limit=99&sortBy=best&bookingStep=outbound`,
    "_blank"
  )
}
