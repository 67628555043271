// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-with-a-travel-agent-js": () => import("./../../../src/pages/book-with-a-travel-agent.js" /* webpackChunkName: "component---src-pages-book-with-a-travel-agent-js" */),
  "component---src-pages-booking-terms-and-conditions-js": () => import("./../../../src/pages/booking-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-booking-terms-and-conditions-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-to-book-js": () => import("./../../../src/pages/how-to-book.js" /* webpackChunkName: "component---src-pages-how-to-book-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-merchants-js": () => import("./../../../src/pages/merchants.js" /* webpackChunkName: "component---src-pages-merchants-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-referral-terms-and-conditions-js": () => import("./../../../src/pages/referral-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-referral-terms-and-conditions-js" */),
  "component---src-pages-rewards-terms-and-conditions-js": () => import("./../../../src/pages/rewards-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-rewards-terms-and-conditions-js" */),
  "component---src-pages-shops-that-accept-bitcoin-js": () => import("./../../../src/pages/shops-that-accept-bitcoin.js" /* webpackChunkName: "component---src-pages-shops-that-accept-bitcoin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-travel-credits-terms-and-conditions-js": () => import("./../../../src/pages/travel-credits-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-travel-credits-terms-and-conditions-js" */),
  "component---src-template-blog-content-js": () => import("./../../../src/template/blog-content.js" /* webpackChunkName: "component---src-template-blog-content-js" */),
  "component---src-template-destination-content-js": () => import("./../../../src/template/destination-content.js" /* webpackChunkName: "component---src-template-destination-content-js" */)
}

