import axios from "axios"
import { Cookies } from "react-cookie"
const cookies = new Cookies()

const api = axios.create({
  baseURL: process.env.API_URL,
  timeout: 40000,
})

api.interceptors.request.use(
  config => {
    if (cookies.get("token")) {
      config.headers.authorization = `bearer ${cookies.get("token")}`
    }
    return config
  },
  error => Promise.reject(error)
)

export default api
