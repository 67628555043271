import React, { useEffect, useState } from "react"
import { getUserData } from "../util/getUserLocation"

const UserContext = React.createContext({})

function UserProvider(props) {
  const [userData, setUserData] = useState({
    userCityCode: null,
    userCity: null,
    userCountry: null,
    userCurrency: "USD",
  })

  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    getUserData().then(res => {
      const { userCityCode, userCity, userCountry, userCurrency } = res
      setUserData({
        userCityCode,
        userCity,
        userCountry,
        userCurrency: userCurrency || "USD",
      })
    })
  }, [])

  useEffect(() => {
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth < 1150)
    )

    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth

    width < 1150 ? setIsMobile(true) : setIsMobile(false)

    return () => window.removeEventListener("resize", () => {})
  }, [])

  const setUserCurrency = currency => {
    setUserData({
      ...userData,
      userCurrency: currency,
    })
  }

  return (
    <UserContext.Provider value={{ userData, isMobile, setUserCurrency }}>
      {props.children}
    </UserContext.Provider>
  )
}

const UserConsumer = UserContext.Consumer

export { UserProvider, UserConsumer }
export default UserContext
