import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import fetch from "isomorphic-fetch"

const httpLink = createHttpLink({
  uri: `${process.env.API_URL}/graphql`,
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
  fetch,
})
