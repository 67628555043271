export const currencies = [
  "USD",
  "AUD",
  "EUR",
  "CNY",
  "INR",
  "SGD",
  "JPY",
  "GBP",
  "KRW",
  "RUB",
]

// Formats string to contain commas where needed
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
