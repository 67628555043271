const theme = {
  orange: "#fd8e0f",
  fontColour: "hsla(0, 0%, 20%, 1)",
  fontColourLight: "#58595b",
  fontColourDarkBlue: "#2d3e52",
  text: "#58595b",
  white: "#fff",
  whiteSnow: "#f9f9f9",
  greyBack: "#f5f5f5",
  lightGrey: "#f1f1f1",
  body: "#414042",
  grey: "#2d3e52",
  darkGrey: "#565656",
  redS: "#f77b7b",
  purple: "#e404cb",
  gradient_left: "#ff6286",
  gradient_right: "#ffa951",
  danger: "hsla(359, 82.3%, 44.3%, 1)",
  fontSizeHeadingMed: "1.125rem", // 18px
  fontSizeHeadingLarge: "1.5rem", // 20px
  fontSizeHeadingLarger: "1.875rem", // 30px
  fontSizeHeadingHuge: "3.4375rem", // 55px
  fontSizeLead: "1rem", // 16px
  fontSizeSmall: "0.875rem", // 14px
  fontSizeTiny: "0.75rem", // 12px
  greyText: "#909090",
}

export const colours = {
  lightpurple: "#db95ee",
  lighterpurple: "#c877de",
  purple: "#a451bb",
  darkpurple: "#752c71",
  lightorange: "#fe9409",
  orange: "#fd8e0f",
  darkorange: "#ef7409",
  orangered: "#f65e4b",
  purpleJ: "#E404CB",
  redJ: "#FD4D73",
  purpleredJ: "#cd1691",
  left_gradient: "#f23e6b",
  right_gradient: "#ff9900",
}

export const color = {
  tangerine: "#EF6C00",
  carrot: "#F6901C",
  apricot: "#FBBB4A",
  black87: "#212121",
  black60: "#666666",
  black38: "#9E9E9E",
  black12: "#E0E0E0",
  bgGrey: "#F9F9F9",
  white: "#FFFFFF",
  error: "#D03333",
}
export const fontSize = {
  h1: "48px",
  h3: "26px",
  h5: "18px",
  body: "16px",
  small: "14px",
  tiny: "12px",
}
export const shadows = {
  normal: "0px 2px 12px 0px rgba(204, 204, 204, 1)",
  light: "0px 2px 7px 0px rgba(220, 220, 220, 1)",
  upward: "0 1px 12px 0 rgba(0, 0, 0, 0.12)",
}

export default theme
