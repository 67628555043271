import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Cookies } from "react-cookie"
import UserContext from "./userContext"
import { Menu, MenuItem, Button } from "@material-ui/core"
import { FaAngleDown } from "react-icons/fa"
import logo from "../images/logos/color-logo.png"
import MenuDrawer from "./ui/menuDrawer"
import { currencies } from "../util/currencyHelpers"
import { useTranslation } from "react-i18next"

// Flag images
import AUD from "../images/flags/australia.svg"
import CNY from "../images/flags/china.svg"
import EUR from "../images/flags/european-union.svg"
import USD from "../images/flags/united-states.svg"
import INR from "../images/flags/india.svg"
import SGD from "../images/flags/singapore.svg"
import JPY from "../images/flags/japan.svg"
import GBP from "../images/flags/uk.svg"
import KRW from "../images/flags/south-korea.svg"
import RUB from "../images/flags/russia.svg"

const { v4: uuidv4 } = require("uuid")
const cookies = new Cookies()

const flags = {
  AUD,
  CNY,
  EUR,
  USD,
  INR,
  SGD,
  JPY,
  GBP,
  KRW,
  RUB,
}

const dropdownOptions = {
  account: [
    { title: "Rewards", link: "/account/rewards" },
    { title: "Booking History", link: "/account/bookings" },
    { title: "Profile", link: "/account/settings" },
    { title: "Credits", link: "/account/credits" },
  ],
}

const Header = ({ location, hero }) => {
  const { userData, isMobile, setUserCurrency } = useContext(UserContext)

  const [anchorEl, setAnchorEl] = useState(null)
  const [language, setLanguage] = useState("en")
  const { t, i18n } = useTranslation()

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCurrencyClick = (currency) => {
    setUserCurrency(currency)
    handleClose()
  }

  const changeLanguage = () => {
    if (i18n.changeLanguage) {
      if (language === "en") {
        i18n.changeLanguage("cn")
        setLanguage("cn")
      } else {
        i18n.changeLanguage("en")
        setLanguage("en")
      }
    }
  }

  return (
    <StyledHeader home={location && location.pathname === "/"} hero={hero}>
      <div className="headerContainer">
        <Link to="/">
          <img src={logo} alt="Travel By Bit" className="logo" />
        </Link>
        {isMobile ? (
          <SliderMenu />
        ) : (
          <Nav home={(location && location.pathname === "/") || hero}>
            {cookies.get("token") ? (
              <a href={`${process.env.TRAVALA_URL}/account`}>
                <Button variant="outlined" className="round">
                  {t("Account")}
                </Button>
              </a>
            ) : (
              <div>
                <a href={`${process.env.TRAVALA_URL}/login`}>
                  <Button variant="outlined" className="round">
                    {t("Log In")}
                  </Button>
                </a>
              </div>
            )}

            <Button onClick={changeLanguage} variant="outlined" className="lng">
              {language === "en" ? "中文" : "Eng"}
            </Button>

            <div className="currencySelect">
              <Button
                aria-controls="currency"
                aria-haspopup="true"
                name="currency"
                variant="outlined"
                className="currency"
                onClick={handleClick}
              >
                <img src={flags[userData.userCurrency]} alt="" />{" "}
                {userData ? t(userData.userCurrency) : t("USD")}
                <FaAngleDown style={{ marginLeft: "0.5em" }} />
              </Button>
              <Menu
                id="currency"
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                open={anchorEl && anchorEl.name === "currency"}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {currencies.map((currency, index) => {
                  return (
                    <MenuItem
                      onClick={() => handleCurrencyClick(currency)}
                      key={uuidv4()}
                    >
                      <img src={flags[currency]} alt={currency} />
                      <p>{t(currency)}</p>
                    </MenuItem>
                  )
                })}
              </Menu>
            </div>
          </Nav>
        )}
      </div>
    </StyledHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const StyledHeader = styled.header`
  width: 100%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 12px -6px rgba(0, 0, 0, 0.35);
  position: relative;
  z-index: 5;
  background: white;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1366px;
    color: #333;
    font-size: 0.875rem;
    margin: 0 auto;
    height: 70px;
    margin-bottom: 30px;
    @media only screen and (max-width: 1420px) {
      width: 100%;
      padding: 0 15px;
    }
    @media only screen and (max-width: 442px) {
      padding: 0 30px;
    }
  }

  ${(props) =>
    props.home &&
    css`
      display: none;
    `}
  ${(props) =>
    props.hero &&
    css`
      background: transparent;
      box-shadow: unset;
      .headerContainer {
        height: 90px;
      }
    `}

  .logo {
    width: 157px;
    height: 31px;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
  }

  .round {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 22px;
    margin-right: 10px;
    .MuiButton-label {
      text-transform: none;
      color: #f66415;
      font-size: 16px;
    }
    ${(props) =>
      props.home &&
      css`
        border: 1px solid rgba(255, 255, 255, 0.12);
      `}
  }

  .lng {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px 0 0 8px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    ${(props) =>
      props.home &&
      css`
        border: 1px solid rgba(255, 255, 255, 0.12);
        color: white;
      `}
  }

  .currencySelect {
    .MuiButton-root {
      border-radius: 0 8px 8px 0;
      box-shadow: unset;
      border: 1px solid rgba(0, 0, 0, 0.38);
      border-left: 0;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
      font-size: 14px;
      ${(props) =>
        props.home &&
        css`
          border: 1px solid rgba(255, 255, 255, 0.12);
          color: white;
        `}
    }

    img {
      height: 1.2em;
      width: auto;
      margin-right: 0.5rem;
    }
  }
`

const SliderMenu = styled(MenuDrawer)`
  margin-left: auto;
  border: 1px solid red;
`
