import React, { useContext, useState } from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import { Cookies } from "react-cookie"
import styled from "styled-components"
import Image from "../image"
import UserContext from "../userContext"
import { currencies } from "../../util/currencyHelpers"
import { useTranslation } from "react-i18next"

// Material UI imports
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import { FaBars } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// Flag images
import AUD from "../../images/flags/australia.svg"
import CNY from "../../images/flags/china.svg"
import EUR from "../../images/flags/european-union.svg"
import USD from "../../images/flags/united-states.svg"
import INR from "../../images/flags/india.svg"
import SGD from "../../images/flags/singapore.svg"
import JPY from "../../images/flags/japan.svg"
import GBP from "../../images/flags/uk.svg"
import KRW from "../../images/flags/south-korea.svg"
import RUB from "../../images/flags/russia.svg"

const { v4: uuidv4 } = require("uuid")
const cookies = new Cookies()

const flags = {
  AUD,
  CNY,
  EUR,
  USD,
  INR,
  SGD,
  JPY,
  GBP,
  KRW,
  RUB,
}

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
})

export default function TemporaryDrawer() {
  const classes = useStyles()
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })
  const { userData, setUserCurrency } = useContext(UserContext)
  const [language, setLanguage] = useState("en")
  const { t, i18n } = useTranslation()

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const handleCurrencyClick = (currency) => {
    setUserCurrency(currency)
  }

  const changeLanguage = () => {
    if (i18n.changeLanguage) {
      if (language === "en") {
        i18n.changeLanguage("cn")
        setLanguage("cn")
      } else {
        i18n.changeLanguage("en")
        setLanguage("en")
      }
    }
  }

  const sideList = (side, data) => (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer(side, false)}
    >
      <Divider />
      <List>
        {cookies.get("token") ? (
          <a href={`${process.env.TRAVALA_URL}/account`}>{t("Account")}</a>
        ) : (
          <div>
            <div className="login">
              <a href={`${process.env.TRAVALA_URL}/login`}>{t("Log In")}</a>
            </div>
          </div>
        )}

        <div className="lan">
          <Button onClick={changeLanguage}>
            {language === "en" ? "中文" : "English"}
          </Button>
        </div>
      </List>
    </div>
  )

  let staticQuery = graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            name
            link
            external
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={`${staticQuery}`}
      render={(data) => (
        <div style={{ marginLeft: "auto" }}>
          <FaBars
            onClick={toggleDrawer("right", true)}
            size="2em"
            color="#fff"
          />
          <Drawer
            anchor="right"
            open={state.right}
            onClose={toggleDrawer("right", false)}
            style={{ zIndex: "999999999" }}
            variant="persistent"
          >
            {sideList("right", data)}
          </Drawer>
        </div>
      )}
    />
  )
}

const Rates = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0 1em 17px;

  p {
    display: flex;
    margin-bottom: 17px;

    &:nth-child(4) {
      margin-bottom: 0;
    }
  }

  .menu-icons {
    width: 20px;
    margin-right: 30px;
  }

  .close {
    position: absolute;
    height: 25px;
    width: 25px;
    transform: translateX(180px) translateY(-3px);
    cursor: pointer;
  }
`

const List = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 1em 0 0 17px;

  a,
  p {
    color: #333;
    font-family: ProximaNova;
    text-decoration: none;
    font-size: 1.2em;
    margin-bottom: 17px;

    &:hover {
      cursor: pointer;
    }
  }

  .MuiAccordionDetails-root > .currency-choice {
    display: flex !important;
    align-items: center;
    margin-bottom: 0.3rem;

    p {
      margin-bottom: 0;
      font-size: 1em;
    }

    img {
      height: 1.5em;
      width: auto;
      margin-right: 1em;
      box-shadow: 0px 11px 42px -13px rgba(0, 0, 0, 0.35);
    }
  }

  a:nth-child(4) {
    margin-bottom: 15px;
  }

  button,
  .MuiButton-contained,
  .MuiButton-root {
    border-radius: 15px;
    box-shadow: unset;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 0.875rem;

    img {
      height: 1.5em;
      width: auto;
      margin-right: 0.5rem;
    }
  }

  .MuiAccordion-root {
    box-shadow: unset;
    width: 200px;
    margin: 0 auto;
    .MuiAccordionSummary-root {
      min-height: unset;
      height: 40px;
      .MuiAccordionSummary-content {
        flex-grow: unset;
        margin-bottom: 0;
        svg {
          margin-left: 5px;
        }
      }
    }
    .MuiAccordionDetails-root {
      display: block;
      padding: 15px 0 0 0;
      a,
      div {
        color: $text_color;
        display: block;
        font-size: 18px;
        line-height: 40px;
        cursor: pointer;
      }
    }
  }

  .MuiAccordion-root,
  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordion-root .MuiAccordionDetails-root {
    cursor: pointer;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    font-size: 1.2em;
    justify-content: flex-start;
  }

  .MuiAccordion-root::before {
    background: none;
  }

  .login {
    margin-bottom: 10px;
  }
  .register {
    margin-bottom: 10px;
  }
`
